* {
	margin: 0;
	box-sizing: border-box;
}

html, body, #root {
	height: 100%;
	padding: 0;
}

body {
	font-size: 0.875rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.43;
	letter-spacing: 0.01071em;
}

.message {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #3f51b5;
	text-transform: uppercase;
}